.services {
  padding: 50px 0;
  .title {
    color: $main-color;
    width: 100%;
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 50px;
    line-height: 1em;
  }
  .service-icon img {
    width: 100px;
  }
  h2 {
    color: $main-color;
    font-weight: 600;
  }
  p {
    color: #526B84;
    font-weight: 200;
  }
}