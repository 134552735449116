.news {
  color: black;
  margin-bottom: 50px;
  .section-title {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 50px;
    text-align: center;
    width: 100%;
  }
  .posts {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
    .post {
      align-items: flex-start;
      border-radius: 5px;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      width: 48%;
      height: 240px;
      padding: 20px;
      .category {
        color: $main-color;
        text-transform: uppercase;
        margin-bottom: 5px;
      }
      .title {
        font-size: 25px;
        margin-bottom: 15px;
      }
      .date {
        color: #6B6C6D;
        margin-bottom: 10px;
      }
      .sub-title {
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }


  @media screen and (max-width: 600px) {
    .posts {
      flex-direction: column;
      .post {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }

  .see-more {
    margin: auto;
    width: fit-content;
    text-align: center;
  }
}