.not-found {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - #{$nav-height});
  }
  .message {
    text-align: center;
    width: 100%;
    .title {
      font-size: 2rem;
      line-height: 100%;
      font-weight: 600;
      margin-bottom: 3rem;
    }

    .subtitle {
      font-size: 1rem;
      margin-bottom: 3rem;
    }

    .button {
      font-size: 1.25rem;
    }
  }
}