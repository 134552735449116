.featured {
  .featured-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;

    .image, .description {
      max-width: 50%;
    }

    .image {
      width: 500px;
      border-radius: 10px;
      margin-bottom: 20px;
    }

    .description {
      margin-left: 20px;
      .title {
        color: $main-color;
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 50px;
      }
      ul {
        padding-left: 0;
        list-style-type: none;
        li {
          font-size: 17px;
          text-align: justify;
          margin-bottom: 30px;
          .icon {
            margin-right: 5px;
            color: #16c995;
          }
        }
      }
    }
  }
  .featured-item.right-image {
    flex-direction: row-reverse;
    .description {
      margin-left: 0;
      margin-right: 20px;
    }
  }

  @media screen and (max-width: 600px) {
    .featured-item, .featured-item.right-image {
      flex-direction: column;
      margin-bottom: 50px;
      .image {
        max-width: 100%;
      }
      .description {
        max-width: 100%;
        width: 100%;
        margin-left: 0;
        margin-right: 0;

        .title {
          font-size: 30px;
        }
      }
    }
  }
}