.landing-hero {
  display: flex;
  min-height: 900px;
  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      color: black;
      font-size: 64px;
      font-weight: 700;
      letter-spacing: -3px;
      line-height: 100%;
      margin-bottom: 10px;
    }
    .subtitle {
      font-size: 34px;
      letter-spacing: 0;
      line-height: 125%;
      margin-bottom: 20px;
    }
    .link {
      &.button {
        font-size: 24px;
        padding: 2% 3%;
      }
    }
  }
  .image {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-height: 768px) {
    min-height: 700px;
  }

  @media screen and (max-width: 1024px) {
    min-height: 0;
    margin-top: 2em;
    margin-bottom: 2em;
    justify-content: center;
    .image {
      display: none;
    }
    .text {
      text-align: center;
      max-width: 50%;
    }
    .link {
      margin: auto;
    }
  }

  @media screen and (max-width:800px) {
    .text {
      max-width: 75%;
    }
  }
}