.site-header {
  min-height: $nav-height;
  .container {
    display: flex;
    justify-content: space-between;
  }

  .logo {
    margin-top: 15px;
  }

  .main-nav {
    display: flex;
    flex: 1;
    ul {
      float: none;
      margin-top: 30px;
    }
    li {
      margin-left: 0;
    }
  }

  $links-color: #5B708B;

  .navigation {
    display: flex;
    flex: 1;
    justify-content: space-evenly;
    margin-left: 1em;
    margin-right: 1em;
  }

  .navigation, .social-networks {
    li {
      list-style: none;
    }
    li > a {
      color: $links-color;
      font-weight: 400;
      display: inline-block;
    }
    li.current > a {
      color: $main-color;
      border-bottom-color: $main-color;
    }
    li > a:hover {
      color: $main-color;
    }
  }

  .social-networks {
    display: flex;
    margin-top: 35px;
    padding-left: 0;
    li {
      margin-left: 0;
      a {
        padding: 5px 8px;
      }
    }
    .fab {
      color: $links-color;
      &:hover {
        color: $main-color;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .social-networks {
      visibility: hidden;
    }
    .navigation {
      visibility: hidden;
    }
  }

  @media screen and (max-width: 1024px) {
    .navigation {
      flex-wrap: wrap;
    }

    .navigation > li {
      flex-basis: 20%;
    }
  }
}
