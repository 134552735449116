.site-footer {
  color: #BDBEC1;
  .container {
    padding-left: 2%;
    padding-right: 2%;
  }

  .footer-col {
    margin-bottom: 30px;
  }
  .address-icon {
    margin-right: 5px;
  }
  .social-networks {
    display: flex;
    font-size: 20px;
    .social-network {
      margin-right: 10px;
      i:hover {
        color: white;
      }
    }
  }
}
