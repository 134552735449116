.post-list-item {
  .post-title {
    a {
      color: $main-color;
      font-weight: 600;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  img {
    max-width: 50%;
  }
}
