body, .pure-g, .pure-g [class*="pure-u"] {
  font-family: "Noto Sans", sans-serif;
  letter-spacing: normal;
}

.button {
  background-color: $main-color;
  border: 0;
  border-radius: 10px;
  color: white;
  text-decoration: none;
  width: fit-content;
  padding: 1% 3%;
  &:hover {
    background-color: #1e2070;
    transition-duration: 300ms;
  }
}

a {
  color: $main-color;
}

.button.outline {
  background-color: white;
  border: 1px solid $main-color;
  border-radius: 10px;
  color: $main-color;
  padding: 6px 20px;
  &:hover {
    background-color: $main-color;
    color: white;
    transition-duration: 300ms;
  }
}

h1, h2, h3 {
  color: #293f99;
}

.content-narrow {
  text-align: justify;
}

@media screen and (min-width: 1024px) {
  section {
    padding: 0 5%;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    margin: 0 2em;
  }
}
