.call-to-action {
  $call-to-action-bg: #3F66C9;

  background-color: $call-to-action-bg;
  color: white;
  height: 270px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .subtitle {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .action {
    font-size: 20px;
    background-color: white;
    color: $call-to-action-bg;
    padding: 10% 10%;
    &:hover {
      background-color: #dddddd;
    }
  }
}