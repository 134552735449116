.contact {
  .fa {
    color: #5B708B;
    margin-right: 5px;
  }
  .social-networks {
    list-style: none;
    display: flex;
    padding-left: 0;
    .social-network {
      margin-right: 0.75em;
      font-size: 2rem;
      a {
        color: #5B708B;
        &:hover {
          color: $main-color;
        }
      }
    }
  }
}